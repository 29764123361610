export default {

    tokenMocks: {
        readAll: {
            permissions: [
                '_read-data',
                '_read-schema',
                '_read-workflow',
                '_read-task',
                '_read-process'],
            groups: ['basic', 'hqs-internal'],
        },
        data: {
            permissions: [
                '_read-data',
                '_write-data',
                '_delete-data',
            ],
            groups: ['basic', 'hqs-internal'],
        }
    }
}