<template>
  <div>
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-text-field
            class="mx-5 mb-2"
            dense
            type="text"
            data-cy="name"
            label="Name"
            v-model="name"
            :rules="[rules.required, rules.noWhitespace]"
            @input="provideFormInfo"
          ></v-text-field>
        </v-row>
        <RightsRadioGroup
          v-for="permission in permissionsObjects"
          :key="permission.type"
          class="mx-5"
          :type="permission.type"
          :readPermission="permission.read"
          :writePermission="permission.write"
          :deletePermission="permission.delete"
        />
      </v-form>
      <!-- additional validation information  -->
      <v-row v-if="showFormInfo">
        <v-col cols="4" />
        <v-col cols="8">
          <v-sheet
            class="text font-weight-light error--text"
            v-for="(element, index) in emptyFormElements"
            :key="index"
            >{{ element }}</v-sheet
          >
        </v-col>
      </v-row>
      <v-row>
        <SuccessAlert
          data-cy="alert"
          class="alert d-sm-inline"
          v-if="showAlert"
          v-bind:type="alertType"
          v-bind:message="alertMessage"
        />
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn data-cy="clear" rounded small text color="darkGrey" @click="clear"
        >clear</v-btn
      >
      <v-btn
        v-if="!valid"
        data-cy="validate"
        rounded
        small
        dark
        depressed
        color="lightGrey"
        @mouseover="triggerFormInfo"
        >create token</v-btn
      >
      <v-btn
        v-else
        data-cy="submit"
        :loading="loading"
        rounded
        small
        :dark="valid"
        depressed
        color="success"
        :disabled="!valid"
        @click="submit"
        >create token</v-btn
      >
    </v-card-actions>
    <!-- token secret dialog -->
    <v-dialog data-cy="dialog" persistent v-model="showDialog" max-width="600">
      <v-card>
        <v-toolbar flat color="blueGreyLight" class="text">
          <v-toolbar-title> Token Secret of {{ tokenName }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn data-cy="close" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-row>
            <SuccessAlert
              data-cy="secret_alert"
              class="alert d-sm-inline"
              v-if="showSecretAlert"
              v-bind:type="alertType"
              v-bind:message="alertMessage"
            />
          </v-row>
          <v-row>
            <v-sheet class="text mt-4" data-cy="info">
              For using your token you need the token ID and the token secret.
              <br />
              The token secret is only availlable as long as this dialog is
              open. <br />
              Please copy it and store it somewhere save. <br />
              The token ID is a combination of the owner ID and the token name
              separeted by a dot. You can find your owner ID above your tokens
              table.
            </v-sheet>
          </v-row>
          <!-- token secret -->
          <v-row>
            <v-col cols="2">
              <v-sheet class="text primary--text text-subtitle-1"
                >Secret:
              </v-sheet>
            </v-col>
            <v-col cols="9">
              <v-row class="mt-1">
                <v-sheet data-cy="secret" class="text mr-1">{{
                  secret
                }}</v-sheet>
                <v-btn
                  data-cy="copy_secret"
                  color="primary"
                  icon
                  @click="copyToClipboard(secret)"
                  x-small
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          <!-- token ID -->
          <v-row>
            <v-col cols="2">
              <v-sheet class="text primary--text text-subtitle-1">ID: </v-sheet>
            </v-col>
            <v-col cols="9">
              <v-row class="mt-1">
                <v-sheet data-cy="token_id" class="text mr-1"
                  >{{ owner }}.{{ tokenName }}</v-sheet
                >
                <v-btn
                  data-cy="copy_id"
                  color="primary"
                  icon
                  @click="copyToClipboard(owner + '.' + tokenName)"
                  x-small
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const SuccessAlert = () => import('../general/SuccessAlert.vue')
const RightsRadioGroup = () => import('./RightsRadioGroup.vue')
import tokenMocks from '../../../tests/e2e/mocks/tokenMocks.js'
import auth from '../../views/lib/auth'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  components: { SuccessAlert, RightsRadioGroup },

  data() {
    return {
      /* form validation */
      valid: false,
      rules: {
        required: (v) => !!v || 'Required',
        noWhitespace: (v) => v && !v.includes(' ') || 'No whitespace allowed',
      },
      /* TokenSecretDialog */
      secret: '',
      tokenName: '',
      showDialog: false,
      /* SuccessAlert */
      showAlert: true,
      showSecretAlert: false,
      alertType: '',
      alertMessage: '',
      /* additional form info on hover */
      emptyFormElements: [],
      showFormInfo: false,
      /* UI machanics */
      loading: false,
      /* setup for tests */
      testUserId: '3cae6a66-5b57-4884-b6e1-01fc9445d5ec',
      isTestUser: false,
    }
  },

  methods: {
    ...mapActions('tokens', ['load', 'loadByID', 'create', 'delete']),

    ...mapMutations('tokens', ['resetUserInputPermissions']),

    prepareAlert(message, type, isSecretAlert) {
      this.alertType = type
      this.alertMessage = message
      if (isSecretAlert) {
        this.showSecretAlert = true
      } else {
        this.showAlert = true
      }
      if (type === 'success' && !isSecretAlert) {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    triggerFormInfo() {
      this.showFormInfo = true
      this.provideFormInfo()
    },

    clear() {
      this.$refs.form.reset()
      this.resetUserInputPermissions()
    },

    close() {
      this.showDialog = false
      this.secret = ''
    },

    async submit() {
      this.loading = true
      let config = {
        permissions: this.userInputPermissions,
        groups: this.userGroups,
      }
      if (this.isTestUser) {
        config = tokenMocks.tokenMocks.data
      }
      this.showAlert = false
      try {
        let token = await this.create([this.name, config])
        this.tokenName = this.name
        this.secret = token.secret
        this.prepareAlert(
          'Your token was created successfully',
          'success',
          false
        )
        this.showDialog = true
        await this.load()
      } catch (error) {
        let errorMessage = JSON.stringify(error.response.data.message, null, 4)
        this.prepareAlert(
          'Your token could not be created: ' + errorMessage,
          'error',
          false
        )
      }
      this.clear()
      this.loading = false
    },

    provideFormInfo() {
      let emptyFormElements = []
      if (!this.valid) {
        if (this.name === '' || this.name === null) {
          emptyFormElements.push('Name is missing!')
        }
      }
      this.emptyFormElements = emptyFormElements
    },

    async cleanupForTesting() {
      //delete all tokens to start testing with a clean slate
      for (let token of this.tokens) {
        await this.delete(token.token_id)
      }
    },

    async createTestingTokens() {
      await this.create(['test_1', tokenMocks.tokenMocks.data])
      this.loading = false
    },

    copyToClipboard(item) {
      this.showAlert = false
      this.copyData = item
      try {
        navigator.clipboard.writeText(JSON.stringify(this.copyData, null, 2))
        this.prepareAlert('Secret copied successfully.', 'success', true)
      } catch (error) {
        this.prepareAlert('Copying failed.', 'error', true)
      }
    },
  },

  computed: {
    ...mapFields('tokens', ['name']),

    ...mapState('tokens', {
      permissionsObjects: (state) => state.permissionsObjects,
      userInputPermissions: (state) => state.userInputPermissions,
      userGroups: (state) => state.userGroups,
      token: (state) => state.token,
      tokens: (state) => state.tokens,
      owner: (state) => state.owner,
    }),

    ...mapState('backend', {
      alertTimeout: (state) => state.alertTimeout,
    }),
  },

  async created() {
    await auth.getUser().then((user) => {
      if (user.id === this.testUserId) {
        this.isTestUser = true
        this.cleanupForTesting()
      }
    })
  },
}
</script>

<style lang="css" scoped></style>
